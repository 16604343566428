'use client'

import { useState } from 'react'
import {
  ContactFormContactUsPage,
  ContactFormContactUsPageMessages,
  ContactFormHomePage,
  ContactFormHomePageMessages,
} from '@/components/devlink'

export type ContactFormProps = {
  variant?: 'home' | 'contact-us';
  communitySelectRuntimeProp?: any;
}

import { submit } from './actions'

export default function ContactForm({ variant = 'home', ...props }: ContactFormProps) {
  const Form = variant === 'home' ? ContactFormHomePage : ContactFormContactUsPage
  const Messages = variant === 'home' ? ContactFormHomePageMessages : ContactFormContactUsPageMessages

  const [formStatus, setFormStatus] = useState('ready')

  const formProps = {
    formRuntimeProps: {
      name: 'contact',
      method: 'post',
      'data-netlify': true,
      'data-netlify-honeypot': 'bot-field',
    },
    formEventRuntimeProps: {
      onSubmit: (e: any) => {
        e.preventDefault()
        const formData = new FormData(e.target)

        submit(formData)
          .then(ok => setFormStatus(ok ? 'success' : 'error'))
      },
    },
  }

  return formStatus === 'ready' ? <Form {...props} {...formProps} />
    : <Messages showError={formStatus === 'error'} showSuccess={formStatus === 'success'} />
}
