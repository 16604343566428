'use client'
import { useEffect, useRef } from 'react'
import { useModal } from '@/components/hooks'
import { PageComponentHome } from '@/components/devlink'

export default function ClientPageComponentHome(props: any) {
  const ref = useRef(null)

  useEffect(() => {
    // @ts-ignore
    ref.current = document.querySelector('.hero-form_text-field')
  }, [])

  const {
    isOpen,
    toggle,
  } = useModal('.search_modal___opacity-_0-hide-me', 'appear')

  useEffect(() => {
    if (!ref.current) {
      // @ts-ignore
      ref.current = document.querySelector('.hero-form_text-field')
    }

    if (isOpen) {
      // @ts-ignore
      setTimeout(() => ref.current.focus(), 1000)
    }
  }, [isOpen])

  return <PageComponentHome
      {...props}
      toggleSearchButtonProps={{ onClick: toggle }}
    />
}
